import { AfterViewInit, Component, ComponentFactoryResolver, ElementRef, Input, OnChanges, QueryList, SimpleChanges, ViewChild, ViewChildren, ViewContainerRef } from '@angular/core';

import { A11y, Mousewheel, Navigation, Pagination } from 'swiper/modules'
import { SwiperOptions } from 'swiper/types';

@Component({
  selector: 'app-swiper',
  templateUrl: './swiper.component.html',
  styleUrls: ['./swiper.component.scss'],
})
export class SwiperComponent implements AfterViewInit, OnChanges {

  @Input() slides: any[] = [];

  @ViewChildren('itens', {read: ViewContainerRef}) public containers!:QueryList<ViewContainerRef>;

  public config: SwiperOptions = {
    modules: [Pagination, A11y, Mousewheel],
    // autoHeight: false,
    spaceBetween: 32,
    // navigation: {
    //   enabled: false,
    //   hideOnClick: true,
    //   nextEl: '.swiper-button-next',
    //   prevEl: '.swiper-button-prev',
    //   disabledClass: 'disabled_swiper_button'
    // },
    pagination: { clickable: true, dynamicBullets: true },
    slidesPerView: 2,
    centeredSlides: true,
    breakpoints: {
      900: {
        modules: [Pagination, A11y, Mousewheel],
        spaceBetween: 32,
        slidesPerView: 3,
        centeredSlides: false,
        // pagination: {el: '.swiper-pagination', clickable: true, dynamicBullets: true },
      },
    }
  }

  ngAfterViewInit() {
    this.loadComponent();
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.loadComponent();

  }

  loadComponent(): void {
    if (this.containers !== null && this.containers !== undefined) {
      this.containers.toArray().forEach((element, index) => {
        const target = this.containers.toArray()[index];
        // console.log(element)
        const ref = target.createComponent(this.slides[index].component);
        Object.keys(this.slides[index].params).forEach(param=>{
          // console.log(this.slides[index]['params'])
          // console.log(this.slides[index].params[param])
          ref.setInput(param, this.slides[index].params[param]);
          ref.changeDetectorRef.detectChanges();
        })
      });
    }
  }
}
