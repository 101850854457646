import { LOCALE_ID, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HttpClientModule } from '@angular/common/http';
import { ReactiveFormsModule } from '@angular/forms';

import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing-routing.module';
import { HomeModule } from './home/home.module';
import { LayoutModule } from './core/layout/layout.module';
import { SharedModule } from './shared/shared.module';

import { CommonModule, registerLocaleData } from '@angular/common';
import ptBr from '@angular/common/locales/pt';
registerLocaleData(ptBr)

import { register } from 'swiper/element/bundle';
import { environment } from './environments/environment';
import { initializeApp, provideFirebaseApp } from '@angular/fire/app';
import { provideFirestore,getFirestore, CACHE_SIZE_UNLIMITED, enableMultiTabIndexedDbPersistence } from '@angular/fire/firestore';
import { AngularFireModule, FIREBASE_OPTIONS } from '@angular/fire/compat';
import { provideAuth,getAuth } from '@angular/fire/auth';
import { provideStorage,getStorage } from '@angular/fire/storage';
import { provideDatabase,getDatabase } from '@angular/fire/database';
import { SubscribeModule } from './subscribe/subscribe.module';
import { NgxMaskDirective, NgxMaskPipe, provideEnvironmentNgxMask } from 'ngx-mask';
import { GoogleMapsModule, GoogleMap } from '@angular/google-maps';
import { AboutModule } from './about/about.module';
import { CompaniesModule } from './companies/companies.module';
import { AmbassadorsModule } from './ambassadors/ambassadors.module';
import { PressModule } from './press/press.module';
import { ResultsModule } from './results/results.module';
import { FaqModule } from './faq/faq.module';
import { CampaignModule } from './campaign/campaign.module';
import { InspireModule } from './inspire/inspire.module';
import { BlogModule } from './blog/blog.module';
import { DataModule } from './data/data.module';
import { PeopleAndCoComponent } from './people-and-co/people-and-co.component';
import { PeopleAndCoModule } from './people-and-co/people-and-co.module';
import { OngsModule } from './ongs/ongs.module';
import { PublicModule } from './public/public.module';
import { FirestoreModule } from '@angular/fire/firestore';
import { NgMapsMarkerClustererModule } from '@ng-maps/marker-clusterer';
import { MapsAPILoader, MapsApiWrapper, NgMapsCoreModule, NoOpMapsAPILoader } from '@ng-maps/core';
import { GoogleMapsAPIWrapper } from '@ng-maps/google';
register();

let resolvePersistenceEnabled: (enabled: boolean) => void;

export const persistenceEnabled = new Promise<boolean>(resolve => {
  resolvePersistenceEnabled = resolve;
});

@NgModule({
  declarations: [
    AppComponent,
  ],
  imports: [
    BrowserModule,
    CommonModule,
    provideFirebaseApp(() => initializeApp(environment.firebase)),
    // provideAuth(() => getAuth()),
    provideFirestore(() => {
      const firestore = getFirestore();
      enableMultiTabIndexedDbPersistence(firestore).then(
        ()=> resolvePersistenceEnabled(true)
      )
      return firestore;
    }),
    provideStorage(() => getStorage()),
    provideDatabase(() => getDatabase()),
    AppRoutingModule,
    BrowserAnimationsModule,
    HttpClientModule,
    ReactiveFormsModule,
    LayoutModule,
    NgxMaskDirective,
    NgxMaskPipe,
    HomeModule,
    AngularFireModule.initializeApp(environment.firebase),
    FirestoreModule,
    SharedModule,
    SubscribeModule,
    AboutModule,
    AmbassadorsModule,
    PressModule,
    ResultsModule,
    FaqModule,
    CampaignModule,
    InspireModule,
    BlogModule,
    DataModule,
    PeopleAndCoModule,
    OngsModule,
    PublicModule,
    CompaniesModule,
    GoogleMapsModule,
    NgMapsMarkerClustererModule,
    NgMapsCoreModule,
  ],
  providers: [
    { provide: FIREBASE_OPTIONS, useValue: environment.firebase },
    { provide: LOCALE_ID, useValue: "pt-BR" },
    { provide: MapsApiWrapper, useClass: GoogleMapsAPIWrapper },
    provideEnvironmentNgxMask(),
    GoogleMap,
    GoogleMapsAPIWrapper

  ],
  bootstrap: [AppComponent]
})
export class AppModule {

 }
