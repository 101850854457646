import { HttpClient } from '@angular/common/http';
import { Component, OnInit, ViewChild } from '@angular/core';
import { Observable, catchError, map, of, tap } from 'rxjs';
import { PartnersService } from '../../services/partners.service';
import { Partner } from '../../models/partner.model';
import { GoogleMap, MapInfoWindow, MapMarker } from '@angular/google-maps';
@Component({
  selector: 'app-map',
  templateUrl: './map.component.html',
  styleUrls: ['./map.component.scss'],
})
export class MapComponent {
  infoContent!:Partner;
  @ViewChild(MapInfoWindow, { static: false }) info!: MapInfoWindow
  @ViewChild(GoogleMap, { static: false }) map!: GoogleMap
  @ViewChild(MapMarker, { static: false }) marker!: MapMarker

  partners!: Partner[];

  icon:google.maps.Icon = {
    url: "assets/icons/google_maps_pin.png", // url
    scaledSize: {height:80, width:80} as google.maps.Size, // scaled size
    // origin: {x:0, y:0} as google.maps.Point, // origin
    // anchor: {x:0, y:0} as google.maps.Point// anchor
};



// equals(other: google.maps.Size|null): boolean;
//     /**
//      * The height along the y-axis, in pixels.
//      */
//     height: number;
//     /**
//      * Returns a string representation of this Size.
//      */
//     toString(): string;
//     /**
//      * The width along the x-axis, in pixels.
//      */
//     width: number;

  markerOptions: google.maps.MarkerOptions = {draggable: false, icon:this.icon};
  apiLoaded: Observable<boolean>;
  openInfo(marker: any, content:any) {
      this.infoContent = content
      this.info.open(marker)
  }

  center: google.maps.LatLngLiteral = {lat: 24, lng: 12};
  zoom = 10;
  display!: google.maps.LatLngLiteral;

  moveMap(event: google.maps.MapMouseEvent) {
    this.center = (event.latLng!.toJSON());
  }

  move(event: google.maps.MapMouseEvent) {
    this.display = event.latLng!.toJSON();
  }

  constructor(httpClient: HttpClient, public partnersService:PartnersService) {
    // If you're using the `<map-heatmap-layer>` directive, you also have to include the `visualization` library
    // when loading the Google Maps API. To do so, you can add `&libraries=visualization` to the script URL:
    // https://maps.googleapis.com/maps/api/js?key=YOUR_API_KEY&libraries=visualization

    this.apiLoaded = httpClient.jsonp('https://maps.googleapis.com/maps/api/js?key=AIzaSyAV5ZxsqC1kusNs7lnpcfkgBq1ITp0wpto', 'callback')
        .pipe(
          map(() => true),
          tap(()=>{
            this.partnersService.partner$.subscribe(res=>{
              this.partners = res
            });
          }),
          catchError(() => of(false)),
        );

    navigator.geolocation.getCurrentPosition(res=>{
      this.center = {lat: res.coords.latitude, lng: res.coords.longitude}
    })



  }

  zoomChanged(){
    const zoom = this.map.getZoom()!;
    this.icon.scaledSize = {height:zoom*4, width: zoom*4} as google.maps.Size;
    this.markerOptions
    console.log(this.marker.cursorChanged)
    console.log(this.icon)
    this.marker.icon = this.icon;
  }

  // zoomScale() {
  //   var scale = 0;
  //   var prevscale = 3;
  //       var zoom:any = this.map.getZoom();

  //       if(zoom < 3)
  //       {
  //           scale = 2;
  //       }
  //       else if(zoom < 5 )
  //       {
  //           scale = 3;
  //       }
  //       else if(zoom < 10)
  //       {
  //           scale = 4;
  //       }
  //       else if(zoom < 15)
  //       {
  //           scale = 5;
  //       }
  //       else if(zoom < 20)
  //       {
  //           scale = 6;
  //       }

  //       // if(scale!=prevscale)
  //       // {
  //       //     var len = markers.length;
  //       //     for(var j=0; j<len; j++)
  //       //     {
  //       //         var icon = markers[j].getIcon();
  //       //         if(icon.hasOwnProperty("scale"))
  //       //         {
  //       //             icon.scale = scale;
  //       //             markers[j].setIcon(icon);
  //       //         }
  //       //     }
  //       // }
  //       prevscale = scale;

  // }

}
