import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ButtonComponent } from './components/button/button.component';
import { CountdownComponent } from './components/countdown/countdown.component';
import { CampaignsComponent } from './components/campaigns/campaigns.component';
import { MatButtonModule } from '@angular/material/button';
import { CampaignCardComponent } from './components/campaigns/campaign-card/campaign-card.component';
import { SwiperDirective } from './directives/swiper.directive';
import { SwiperComponent } from './components/swiper/swiper.component';
import {MatCardModule} from '@angular/material/card';
import { ImpactComponent } from './components/impact/impact.component';
import { WhatsappBannerComponent } from './components/whatsapp-banner/whatsapp-banner.component';
import { ParticipateComponent } from './components/participate/participate.component';
import { DiscoverComponent } from './components/discover/discover.component';
import { NewsComponent } from './components/news/news.component';
import { LeaderComponent } from './components/leader/leader.component';
import { DonorsComponent } from './components/donors/donors.component';
import { DepositionsComponent } from './components/depositions/depositions.component';
import { DepositionCardComponent } from './components/depositions/deposition-card/deposition-card.component';
import { MapComponent } from './components/map/map.component';
import { GoogleMapsModule, GoogleMap, MapMarker} from '@angular/google-maps';
import { HttpClientModule, HttpClientJsonpModule } from '@angular/common/http';
import { DetailComponent } from './components/detail/detail.component';
import { RouterModule } from '@angular/router';
import { NoProfitComponent } from './components/no-profit/no-profit.component';
import { LinksComponent } from './components/links/links.component';
import { CommonQuestionsComponent } from './components/common-questions/common-questions.component';
import { MatExpansionModule } from '@angular/material/expansion';
import { PrincipalResultsComponent } from './components/principal-results/principal-results.component';
import { MatInputModule } from '@angular/material/input';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatFormFieldModule } from '@angular/material/form-field';
import { KidsComponent } from './components/kids/kids.component';
import { ResultsComponent } from './components/results/results.component';
import { ResourcesComponent } from './components/resources/resources.component';
import { QuickFactsComponent } from './components/quick-facts/quick-facts.component';
import { MediaComponent } from './components/media/media.component';
import { OfficializedCitiesComponent } from './components/officialized-cities/officialized-cities.component';
import { SingleNewsComponent } from './components/single-news/single-news.component';
import { MatDialogModule } from '@angular/material/dialog';
import { ShowAmbassadorComponent } from './components/show-ambassador/show-ambassador.component';
import { FollowComponent } from './components/follow/follow.component';
import { MatIconModule } from '@angular/material/icon';
import { B64InputComponent } from './components/b64-input/b64-input.component';
import { BrowserModule } from '@angular/platform-browser';
import { MatDividerModule } from '@angular/material/divider';
import { OtherHistoriesComponent } from './other-histories/other-histories.component';
import { ReportsComponent } from './components/reports/reports.component';
import {MatGridListModule} from '@angular/material/grid-list';
import { CategoryNewsComponent } from './components/category-news/category-news.component';
import { NewsDeatilComponent } from './components/news-deatil/news-deatil.component';
import { MatChipsModule } from '@angular/material/chips';
import { SuccessDialogComponent } from './components/success-dialog/success-dialog.component';
import { StoreButtonComponent } from './components/store-button/store-button.component';
import { NgMapsMarkerClustererModule } from '@ng-maps/marker-clusterer';
import { MapsApiWrapper } from '@ng-maps/core';
import { GoogleMapsAPIWrapper } from '@ng-maps/google';


@NgModule({
  declarations: [
    ButtonComponent,
    CountdownComponent,
    CampaignsComponent,
    CampaignCardComponent,
    SwiperDirective,
    SwiperComponent,
    ImpactComponent,
    WhatsappBannerComponent,
    ParticipateComponent,
    DiscoverComponent,
    NewsComponent,
    LeaderComponent,
    DonorsComponent,
    DepositionsComponent,
    DepositionCardComponent,
    MapComponent,
    DetailComponent,
    NoProfitComponent,
    LinksComponent,
    CommonQuestionsComponent,
    PrincipalResultsComponent,
    KidsComponent,
    ResultsComponent,
    ResourcesComponent,
    QuickFactsComponent,
    MediaComponent,
    OfficializedCitiesComponent,
    SingleNewsComponent,
    ShowAmbassadorComponent,
    FollowComponent,
    B64InputComponent,
    OtherHistoriesComponent,
    ReportsComponent,
    CategoryNewsComponent,
    NewsDeatilComponent,
    SuccessDialogComponent,
    StoreButtonComponent
  ],
  imports: [
    BrowserModule,
    CommonModule,
    MatButtonModule,
    MatCardModule,
    GoogleMapsModule,
    HttpClientModule,
    HttpClientJsonpModule,
    RouterModule,
    MatExpansionModule,
    MatInputModule,
    MatFormFieldModule,
    MatInputModule,
    ReactiveFormsModule,
    MatButtonModule,
    MatDialogModule,
    MatIconModule,
    FormsModule,
    MatDividerModule,
    MatGridListModule,
    MatChipsModule,
    NgMapsMarkerClustererModule
  ],
  exports:[
    ButtonComponent,
    CountdownComponent,
    CampaignsComponent,
    ImpactComponent,
    WhatsappBannerComponent,
    ParticipateComponent,
    DiscoverComponent,
    NewsComponent,
    LeaderComponent,
    DonorsComponent,
    DepositionsComponent,
    DepositionCardComponent,
    MapComponent,
    DetailComponent,
    NoProfitComponent,
    LinksComponent,
    CommonQuestionsComponent,
    PrincipalResultsComponent,
    KidsComponent,
    ResultsComponent,
    ResourcesComponent,
    QuickFactsComponent,
    MediaComponent,
    OfficializedCitiesComponent,
    FollowComponent,
    B64InputComponent,
    OtherHistoriesComponent,
    ReportsComponent,
    CategoryNewsComponent,
    NewsDeatilComponent,
    StoreButtonComponent
  ],
  providers:[GoogleMap, MapMarker,
    GoogleMapsAPIWrapper],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class SharedModule { }
