import { Component } from '@angular/core';
import { FaqService } from 'src/app/shared/services/faq.service';

@Component({
  selector: 'app-faq-list',
  templateUrl: './faq-list.component.html',
  styleUrls: ['./faq-list.component.scss']
})
export class FaqListComponent {

  constructor(public faqService: FaqService){
    this.faqService.getfaqs();
  }

}
